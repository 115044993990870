<template>
    <div>
      <v-layout wrap>
        <v-flex pa-4 xs12 text-center>
          <v-card>
            <v-layout wrap justify-center>
              <v-flex pl-4 pt-2 pb-2 xs12 text-left style="background-color: black;">
                <span style="font-size: 20px;color: white;">
                  Applicant Details
                </span>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap>

                    <v-flex pl-3 xs3 pt-6 pb-6 text-left align-self-center>
                    <v-text-field dense color="#283e51" outlined background-color="white" v-model="memberCount" hide-details>
                      <template v-slot:label>
                        <span class="custom-label-text" style="color: black; font-size: 14px">
                            Member Count*
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex pl-3 xs3 pt-6 pb-6 text-left align-self-center>
                    <v-autocomplete
                      outlined dense color="black"
                      :items="applicantcategorys"
                      v-model="applicantCategory"
                      item-text="name" item-value="name"
                      item-color="#FF1313"
                      hide-details clearable
                      class="custom-autocomplete mainfont"
                    >
                      <template v-slot:label>
                        <span class="custom-label-text" style="color: black; font-size: 14px">
                          applicantCategory *
                        </span>
                      </template>
                      <template v-slot:append>
                        <v-icon color="black">mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        memberCount:null,
        applicantCategory:"",
        applicantcategorys: [
          "Students",
          "Press Reporters",
          "LSG Members",
          "NGO youth/charitable organization",
        ],
      };
    },
    watch: {
  applicantCategory(newVal) {
    this.$emit("applicant-details-updated", {
      memberCount: this.memberCount,
      applicantCategory: newVal,
    });
  },
},


  };
  </script>
  